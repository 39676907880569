import {Link} from 'react-router-dom';

interface AIMusicSectionProps {
  className?: string;
}
export function AIMusicSection({}: AIMusicSectionProps) {
  return (
    <>
      <div className="mt-40 md:mt-60">
        <div className="flex items-center flex-col w-full md:max-w-[75%] mx-auto text-center">
          <div className="inline-flex flex-wrap items-center gap-12 border border-gray-700 rounded-xl p-4 text-[#D0D5DD] font-medium text-sm">
            <span className="border border-gray-700 text-white py-2 px-8 rounded-md	flex items-center gap-x-6">
              <img alt="dot" src="images/icons/green-dot.svg" />
              Feature
            </span>
            <span className="flex items-center gap-x-4">Endless Library</span>
          </div>

          <h2 className="text-white text-4xl font-semibold mt-12">
            AI-Infused Music Discovery
          </h2>
          <h6 className="text-[#D0D5DD] text-xl font-normal mt-20">
            Unveiling the Future of Music: Discover, Stream, and Embrace
            <br />
            AI-Generated Tracks
          </h6>
        </div>
        <div className="mt-64">
          <div className="flex flex-col md:flex-row items-stretch border border-[#424242] bg-[#141414] rounded-xl gap-64 p-32">
            <div className="w-full md:w-2/4 flex items-center">
              <div className="flex rounded-xl overflow-hidden border border-[#424242] h-full">
                <img
                  className="object-contain"
                  src="images/landing/landing-ai-music1.jpg"
                />
              </div>
            </div>
            <div className="w-full md:w-2/4">
              <div className="w-48 h-48 rounded-xl bg-[#292929] flex items-center justify-center">
                <img
                  src="images/icons/music-icon-1.png"
                  alt="music icon"
                  className="w-24 h-24"
                />
              </div>
              <h5 className="text-white text-3xl font-semibold mt-12">
                Endless AI Music Library
              </h5>
              <p className="text-[#D0D5DD] text-lg font-normal mt-20">
                Explore an infinite collection of AI-composed music. Our
                platform offers a vast and ever-expanding library of original
                tracks across various genres, ensuring a fresh and unique
                listening experience.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-32 md:flex-row items-stretch mt-32 ">
            <div className="w-full md:w-2/4 border border-[#424242] bg-[#141414] rounded-xl flex  flex-col gap-32 p-32 pb-0">
              <div>
                <div className="w-48 h-48 rounded-xl bg-[#292929] flex items-center justify-center">
                  <img
                    src="images/icons/music-icon-2.svg"
                    alt="music icon"
                    className="w-24 h-24"
                  />
                </div>
                <h5 className="text-white text-3xl font-semibold mt-12">
                  Personalized Recommendations
                </h5>
                <p className="text-[#D0D5DD] text-lg font-normal mt-20">
                  Discover AI-generated music tailored to your tastes. Our
                  intelligent algorithms analyze your preferences and listening
                  history to curate personalized playlists and suggest new
                  tracks you'll love.
                </p>
              </div>
              <div className="mt-auto">
                <img src="images/landing/landing-ai-music2.png" />
              </div>
            </div>
            <div className="w-full md:w-2/4 border border-[#424242] bg-[#141414] rounded-xl flex flex-col gap-32 p-32 pb-0">
              <div>
                <div className="w-48 h-48 rounded-xl bg-[#292929] flex items-center justify-center">
                  <img
                    src="images/icons/music-icon-3.png"
                    alt="music icon"
                    className="w-24 h-24"
                  />
                </div>
                <h5 className="text-white text-3xl font-semibold mt-12">
                  Genre-Diverse AI Compositions
                </h5>
                <p className="text-[#D0D5DD] text-lg font-normal mt-20">
                  Experience the versatility of AI-generated music across
                  genres. From classical masterpieces to contemporary beats, our
                  AI composers create captivating compositions in every style.
                </p>
              </div>
              <div className="mt-auto">
                <img src="images/landing/landing-ai-music3.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`relative z-10 px-28 md:px-64 py-40 md:py-96 my-46 md:my-80 signup-card-bg-image bg-no-repeat bg-cover rounded-2xl signup-card`}
      >
        <div className="flex flex-col gap-20">
          <h4 className="text-white text-5xl font-bold ">
            Join the AI Music Revolution
          </h4>
          <p className="text-white font-normal text-xl w-full md:w-[75%]">
            Start Listening to Endless Possibilities: Sign Up Now and Dive into
            the World of AI-Generated Music
          </p>
        </div>
        <div className="h-50 mt-40">
          <div className="absolute z-10 gap-12 flex ">
            <Link
              to="/register"
              className="hover:opacity-80 px-20 py-12 bg-white text-[#344054] text-base font-semibold rounded-lg border border-gray-300 shadow-[0_35px_60px_-15px_rgba(16,24,40,0.05)]"
            >
              Sign Up
            </Link>
            <Link
              to="/discover"
              className="hover:opacity-80 px-20 py-12 text-white text-base font-semibold rounded-lg border border-[#AAF0C4] bg-light-green shadow-[0px_8px_48px_0px_rgba(29,218,99,0.80)]"
            >
              Discover
            </Link>
          </div>
        </div>
        <div className="flex absolute z-1 w-[80%] min-h-[300px] left-[50%] transform -translate-x-1/2 -bottom-50 md:-bottom-80 bg-sec-gradient"></div>
      </div>
    </>
  );
}
