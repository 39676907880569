import {ArrowSmallDownIcon} from '@heroicons/react/24/solid';

interface StreamDetailSectionProps {
  className?: string;
}
export function StreamDetailSection({}: StreamDetailSectionProps) {
  return (
    <>
      <div className="my-40 md:my-60">
        <div className="flex items-center flex-col w-full md:max-w-[75%] mx-auto text-center">
          <div className="inline-flex flex-wrap items-center gap-12 border border-gray-700 rounded-xl p-4 text-[#D0D5DD] font-medium text-sm">
            <span className="border border-gray-700 text-white py-2 px-8 rounded-md	flex items-center gap-x-6">
              <img alt="dot" src="images/icons/green-dot.svg" />
              Focus
            </span>
            <span className="flex items-center gap-x-4">Unique Music</span>
          </div>

          <h2 className="text-white text-4xl font-semibold mt-12">
            Made for You
          </h2>
          <h6 className="text-[#D0D5DD] text-xl font-normal mt-20">
            Stream Anytime, Anywhere. From Any Device. Stream music in the
            browser, on Phone, Tablet, Smart TVs, Consoles, Chromecast, Apple TV
            and more.
          </h6>
        </div>
        <div className="mt-40 md:mt-60 ">
          <div className="mt-40 md:mt-60 relative">
            <img src="images/landing/landing-sec2.png" />
            <div className="flex absolute w-[80%] min-h-[200px] left-[50%] transform -translate-x-1/2 bottom-0 bg-sec-gradient"></div>
          </div>
        </div>

        <div className="h-2 absolute w-full left-0 gradient-divider"></div>
      </div>
      <div className="py-20 md:py-40 flex items-center flex-col gap-y-10 ">
        <div className="w-0 h-48 border border-[rgba(255,255,255,0.20)]"></div>
        <div className="mx-auto h-32 w-32 flex justify-center items-center bg-transparent border border-[rgba(255,255,255,0.20)] rounded-full">
          <ArrowSmallDownIcon className="h-16 w-16 text-white stroke-2" />
        </div>
        <div className="w-0 h-48 border border-[rgba(255,255,255,0.20)]"></div>
      </div>
    </>
  );
}
