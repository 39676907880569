import {Link} from 'react-router-dom';
import {
  ArrowSmallDownIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/24/solid';

interface HeroSectionProps {
  className?: string;
}
export function HeroSection({}: HeroSectionProps) {
  return (
    <div className="relative mb-70">
      <video
        className="hidden md:block absolute bottom-[8%] left-[28%] w-full h-full object-cover -z-1"
        autoPlay
        muted
        loop
      >
        <source src="videos/hero-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className="full-width md:max-w-[65%] gap-y-24 flex flex-col py-30 md:py-90 relative"
        style={{position: 'relative'}}
      >
        <div>
          <div className="inline-flex flex-wrap items-center gap-12 border border-gray-700 rounded-xl p-4 pr-10 text-[#D0D5DD] font-medium text-sm">
            <span className="border border-gray-700 text-white py-2 px-8 rounded-md	flex items-center gap-x-6">
              <img alt="dot" src="images/icons/green-dot.svg" />
              Try Now!
            </span>
            <span className="flex items-center gap-x-4">
              Unlock Limitless AI Music
              <ArrowSmallRightIcon className="h-20 w-20 text-[#667085] stroke-2" />
            </span>
          </div>
        </div>
        <h2 className="text-5xl md:text-7xl	font-bold text-white">
          Experience the Generative Future
        </h2>
        <h6 className="text-lg md:text-xl font-normal text-white">
          — Discover, stream, and share a constantly expanding mix of AI music
          from emerging and major artists around the world.
        </h6>

        <div className="gap-12 flex mt-24">
          <Link
            to="/register"
            className="px-28 py-16 bg-white text-[#344054] text-lg font-semibold rounded-lg border border-gray-300 shadow-xs hover:opacity-80"
          >
            Sign Up
          </Link>
          <Link
            to="/discover"
            className="px-28 py-16 text-white text-lg font-semibold rounded-lg border border-white bg-[#0F0F0F] shadow-[0px_8px_48px_0px_rgba(29,218,99,0.80)] hover:opacity-80"
          >
            Discover
          </Link>
        </div>
      </div>
      <div className="mt-30 md:mt-0 relative mx-auto h-32 w-32 flex justify-center items-center bg-light-green rounded-full">
        <ArrowSmallDownIcon className="h-20 w-20 text-grey-black stroke-2" />
      </div>
    </div>
  );
}
