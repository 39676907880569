import {SearchIcon} from '@common/icons/material/Search';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {MagnifyingGlassIcon} from '@heroicons/react/24/solid';

export interface SearchBarProps {
  placeholder?: string;
}

export function SearchBar(props: SearchBarProps) {
  let {placeholder} = props;
  const navigate = useNavigate();

  return (
    <div className="flex-grow justify-center flex items-center">
      <form
        onSubmit={e => {
          e.preventDefault();
          navigate(`search/${(e.currentTarget[0] as HTMLInputElement).value}`);
        }}
      >
        <div className="relative mt-2 rounded-md shadow-sm w-400">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-10">
            <MagnifyingGlassIcon className="h-20 w-20 text-gray-400" />
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className="bg-transparent block w-full rounded-full border-0 py-6 pl-36 text-base font-normal text-white-900 ring-1 ring-inset ring-[#424242] placeholder:text-base placeholder:text-[#737373]"
            placeholder={placeholder}
          />
        </div>
      </form>
    </div>
  );
}
