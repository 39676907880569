import {Link} from 'react-router-dom';

interface FooterProps {
  className?: string;
}

const FOOTER_OPTIONS = [
  {
    title: 'Overview',
    path: '/overview',
  },
  {
    title: 'Features',
    path: '/features',
  },
  {
    title: 'Pricing',
    path: '/pricing',
  },
  {
    title: 'Help',
    path: '/help',
  },
  {
    title: 'Privacy',
    path: 'privacy',
  },
];
const FOOTER_SUB_OPTIONS = [
  {
    title: 'Terms',
    path: '/pages/terms-of-service',
  },
  {
    title: 'Privacy',
    path: '/pages/privacy-policy',
  },
  // {
  //   title: 'Cookies',
  //   path: '/cookies',
  // },
];
export function Footer({}: FooterProps) {
  return (
    <div>
      <div className="h-2 absolute w-full left-0 gradient-divider"></div>
      <div className="py-64 flex sm:items-center flex-col gap-32 px-20">
        <Link to="/" className="block mr-4 md:mr-24 flex-shrink-0 h-full">
          <img className="block w-[120px] h-full" src="images/logo.png" />
        </Link>
        {/*<div className="flex sm:items-center gap-20 sm:gap-32 flex-col sm:flex-row">*/}
        {/*  {FOOTER_OPTIONS.map((ele: {title: string; path: string}) => {*/}
        {/*    return (*/}
        {/*      <Link*/}
        {/*        key={'footer' + ele.title}*/}
        {/*        to={ele.path}*/}
        {/*        className="hover:underline text-base text-gray-200 font-semibold"*/}
        {/*      >*/}
        {/*        {ele.title}*/}
        {/*      </Link>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}
      </div>
      <div className="pt-32 pb-48 flex flex-col sm:flex-row gap-20 items-center border-t border-[#344054]">
        <div className="flex items-center gap-16">
          {FOOTER_SUB_OPTIONS.map((ele: {title: string; path: string}) => {
            return (
              <Link
                key={'footer_sub_' + ele.title}
                to={ele.path}
                className="hover:underline text-base text-[#D0D5DD] font-normal"
              >
                {ele.title}
              </Link>
            );
          })}
        </div>
        <p className="sm:ml-auto text-base text-[#D0D5DD] font-normal">
          © 2023 LastDigit all rights reserved.
        </p>
      </div>
    </div>
  );
}
